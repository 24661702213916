import React from 'react';
import { ProductInfo, Subscription, UsageInfo } from '../services/models/SubscriptionInfoDTO';

export interface ApiStateContextProps {
  isLoading: boolean;
  subscriptions: Array<Subscription>;
  usage: Array<UsageInfo>;
  products: Array<ProductInfo>;
  curSub?: Subscription;
  curUsage?: UsageInfo;
  curProduct?: ProductInfo;
  refresh: () => Promise<void>;
}

const ApiStateContext = React.createContext<ApiStateContextProps>(
  undefined as ApiStateContextProps
);

export default ApiStateContext;
