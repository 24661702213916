import { createBreakpoints } from '@mui/system';
import { IThemeOptions } from '.';

const breakpoints = createBreakpoints({
  values: {
    xs: 400,
    sm: 600,
    smallMobile: 320,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const DashboardOptions: IThemeOptions = {
  breakpoints,
  themeName: 'Guitar2Tabs',
  components: {
    // mui v5 (fontsizes has changed)
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
        kbd: {
          fontFamily: 'Poppins, sans-serif',
          backgroundColor: '#eee',
          borderRadius: '3px',
          border: '1px solid #b4b4b4',
          boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset',
          color: '#333',
          display: 'inline-block',
          fontSize: '1em',
          fontWeight: 400,
          lineHeight: 1,
          padding: '2px 4px',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
          boxShadow: 'none', // Optional, removes shadow if any
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#1B1A1B',
          boxShadow: 'none', // Optional, removes shadow if any
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: '#15B9E2',
          '&.Mui-disabled': {
            color: '#67a0af',
          },
        },
        outlinedPrimary: {
          color: '#15B9E2',
          '&.Mui-disabled': {
            color: '#67a0af',
            border: '1px solid #67a0af',
          },
        },
        containedPrimary: {
          backgroundColor: '#15B9E2',
          '&.Mui-disabled': {
            backgroundColor: '#67a0af',
            color: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          color: '#fff',
          backgroundColor: '#15B9E2',
          background: '#15B9E2', // transcribe button
          '&.Mui-disabled': {
            color: '#fff',
            background: '#26282a33',
          },
        },
        secondary: {
          color: '#fff',
          backgroundColor: '#636D76',
          background: '#636D76', // transcribe button
          '&.Mui-disabled': {
            color: '#fff',
            background: '#26282a33',
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#15B9E2',
      light: '#D5EDFC',
      dark: '#03AFB4',
      contrastText: '#000',
    },
    secondary: {
      main: '#636D76',
      light: '#00000050',
      dark: '#2F3942',
      contrastText: '#fff',
    },
    warning: {
      main: '#f3e841',
      light: '#fde998',
      dark: '#f57c00',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    background: {
      default: '#26282a',
      paper: '#1B1A1B',
    },
    divider: '#ffffff12',
  },
  gradients: {
    primaryGradient: 'linear-gradient(-225deg, #2d2d2d 30%, #111111 95%)',
    secondaryGradient: 'linear-gradient(-225deg, #f2f7fb 30%, #fff 100%)',
  },
  typography: {
    fontFamily: ['Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      color: '#fff',
      marginTop: 30,
      fontSize: '2.25rem',
      lineHeight: '1.15em',
      fontWeight: 400,
      [breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        marginTop: 0,
      },
    },
    subtitle1: {
      fontSize: '1.125rem',
      color: '#ffffffb3',
      marginTop: '10px',
      [breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
  },
};

export default DashboardOptions;
