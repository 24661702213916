import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { UsersService, UserDTO } from '@klangio/ai2notes-ts-client';

import Logo from '../components/Logo';

import { bgGradient } from '../theme/css';
import useRouter from '../hooks/useRouter';

export const REGISTER_HREF_URL = 'https://auth.klang.io/register?redirect_url=';
export const LOGIN_HREF_URL = 'https://auth.klang.io/login?redirect_url=';
export const LOGOUT_HREF_URL = 'https://auth.klang.io/logout?redirect_url=';

const LoginPage = () => {
  const theme = useTheme();

  const router = useRouter();

  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window.location.href.includes('.klang.io')) {
      window.location.href = `${LOGIN_HREF_URL}${encodeURIComponent(window.location.origin)}`;
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit');
    ev.preventDefault();
    if (window.location.href.includes('.klang.io')) {
      // handle login
      window.location.href = LOGIN_HREF_URL;
    } else {
      setIsLoading(true);
      const target = ev.target as typeof ev.target & {
        email: { value: string };
        password: { value: string };
      };

      const email = target.email.value;
      const password = target.password.value;
      UsersService.usersLogin({ email, password })
        .then((user: UserDTO) => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          window.location.href = '/';
        })
        .catch((error) => {
          if (typeof error === 'object') {
            console.log(JSON.stringify(error, null, 2));
          } else {
            console.log(error);
          }
        })
        .finally(() => setIsLoading(false));
      router.push('/');
    }
  };

  const renderForm = (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
        <Link variant="subtitle2" underline="hover" href="/">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
        Login
      </LoadingButton>
    </>
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: 'fixed',
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">Sign in to Klangio API Dashboard</Typography>

          <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            Don’t have an account?
            <Link
              href={REGISTER_HREF_URL + encodeURIComponent(window.location.toString())}
              variant="subtitle2"
              sx={{ ml: 0.5 }}
            >
              Get started
            </Link>
          </Typography>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              OR
            </Typography>
          </Divider>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
};

export default LoginPage;
