import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import ApiUserContext, { IApiUserContextValue } from './ApiUserContext';
import ApiUserService from '../services/ApiUserService';
import UserContext from './UserContext';
import { ApiUser } from '../services/models/ApiUser';

const LOGOUT_URL = typeof window !== 'undefined' && window.location.hostname.includes('dashboard.klang.io')
  ? 'https://auth.klang.io/logout'
  : 'https://auth-test.klang.io/logout';

const ApiUserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user: ai2notesUser, isLoadingUserCompleted } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<ApiUser>();

  const navigate = useNavigate();

  useEffect(() => {
    console.log('ApiUserProvider, value: ', user);
  }, [user]);

  const refresh = useCallback(() => {
    if (!ai2notesUser) return;
    setIsLoading(true);
    setTimeout(() => {
      ApiUserService.usersCheckOrCreateUser()
        .then((newUser) => {
          console.log('Fetched user: ', newUser);
          setUser(newUser);
        })
        .catch((err) => {
          console.error(err);
          setUser(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 1000);
  }, [setIsLoading, setUser, ai2notesUser]);

  useEffect(() => {
    if (
      typeof ai2notesUser === 'undefined'
      && isLoadingUserCompleted
      && window.location.href.includes('localhost')
    ) {
      navigate('/login');
    } else if (isLoadingUserCompleted && typeof ai2notesUser !== 'undefined') {
      refresh();
    }
  }, [ai2notesUser, isLoadingUserCompleted, refresh]);

  const shouldDisplayChildren = (!isLoading && typeof user !== 'undefined')
    || (typeof ai2notesUser === 'undefined' && isLoadingUserCompleted);
  const shouldDisplayErrorMessage = !isLoading && typeof user === 'undefined';

  const value = useMemo<IApiUserContextValue>(() => {
    return {
      isLoading,
      userData: user,
      refresh,
    };
  }, [isLoading, user]);

  return (
    <ApiUserContext.Provider value={value}>
      {shouldDisplayErrorMessage && (
        <Box
          sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'now' },
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert severity="error">
            <AlertTitle>Error Occurred</AlertTitle>
            Could not create and/or fetch user related information from the API.
            {' '}
            <br />
            <br />
            This might be a temporal issue, get in touch with the Support if this error persists.
          </Alert>
          <Button sx={{ mt: 4 }} variant="outlined" href={LOGOUT_URL}>
            Back to Klangio Homepage
          </Button>
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'now' },
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Loading User Data</Typography>
          <Skeleton height={32} width="50%" animation="pulse" />
        </Box>
      )}
      {shouldDisplayChildren && children}
    </ApiUserContext.Provider>
  );
};

export default ApiUserProvider;
