import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import { bgBlur } from '../theme/css';

import { useResponsive } from '../hooks/useResponsive';

/* import AccountPopover from './common/account-popover';
import NotificationsPopover from './common/notifications-popover'; */

import { NAV_WIDTH_PX } from './Nav';
import { UserContext } from '../context';
import { LOGOUT_HREF_URL } from '../pages/Login';

export const HEADER_HEIGHT_MOBILE_PX = 64;
export const HEADER_HEIGHT_DESKTOP_PX = 80;

const Header: React.FC<{ onOpenNav: () => void }> = ({ onOpenNav }) => {
  const theme = useTheme();
  const { user, logout } = useContext(UserContext);
  const lgUp = useResponsive('up', 'lg');

  const handleLogoutClick = () => {
    logout().then(() => {
      window.location.href = `${LOGOUT_HREF_URL}https%3A%2F%2Fklang.io`;
    });
  };

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <MenuIcon sx={{ color: 'white' }} />
        </IconButton>
      )}

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={1}>
        {/*         <NotificationsPopover />
        <AccountPopover /> */}
        {user && (
          <Button onClick={handleLogoutClick} startIcon={<LogoutIcon />}>
            Logout
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sx={{
        boxShadow: 'none',
        height: HEADER_HEIGHT_MOBILE_PX,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV_WIDTH_PX + 1}px)`,
          height: HEADER_HEIGHT_DESKTOP_PX,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
