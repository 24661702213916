import React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { alpha } from '@mui/material/styles';
import usePathname from '../hooks/usePathname';
import RouterLink from './RouterLink';

export type Item = { icon: React.ReactNode; title: string; path: string };

const NavItem: React.FC<{ item: Item }> = ({ item }) => {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      LinkComponent={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">
        {item.title}
        {' '}
      </Box>
    </ListItemButton>
  );
};

export default NavItem;
