import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ApiStateContext from './ApiStateContext';
import UserContext from './UserContext';
import ApiUserService from '../services/ApiUserService';
import { ProductInfo, Subscription, UsageInfo } from '../services/models/SubscriptionInfoDTO';

export const useApiState = () => {
  const values = useContext(ApiStateContext);
  if (typeof values === 'undefined') throw new Error('ApiStateContext is not defined');
  return values;
};

const ApiStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [usage, setUsage] = useState([]);
  const [products, setProducts] = useState([]);

  const [curSub, setCurSub] = useState<Subscription>();
  const [curUsage, setCurUsage] = useState<UsageInfo>();
  const [curProduct, setCurProduct] = useState<ProductInfo>();

  const [isLoading, setIsLoading] = useState(true);

  const refresh = useCallback(() => {
    setIsLoading(true);
    return ApiUserService.usersSubscription()
      .then((data) => {
        setSubscriptions(data.subscriptions);
        setProducts(data.productInfos);
        setUsage(data.usageInfo);

        const tSub = data.subscriptions[0];
        setCurSub(tSub);

        // determine curUsage
        if (tSub && data.usageInfo) {
          const tUsage = data.usageInfo
            .sort((a, b) => {
              return new Date(a.interval_start).getTime() - new Date(b.interval_start).getTime();
            })
            .find((val) => val.id === tSub.cur_usage_id);
          setCurUsage(tUsage);
        }

        // determine curProduct
        if (tSub && data.productInfos) {
          const tProd = data.productInfos.find((item) => item.id === tSub.p_id);
          setCurProduct(tProd);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      refresh();
    } else {
      setSubscriptions([]);
      setUsage([]);
      setProducts([]);
    }
  }, [user]);

  const value = useMemo(() => {
    return {
      subscriptions,
      usage,
      products,
      isLoading,
      curSub,
      curUsage,
      curProduct,
      refresh,
    };
  }, [subscriptions, usage, products, isLoading, refresh]);

  return <ApiStateContext.Provider value={value}>{children}</ApiStateContext.Provider>;
};

export default ApiStateProvider;
