/// <reference lib="dom" />
import { OpenAPI } from '@klangio/ai2notes-ts-client';
import { SubscriptionResponse } from './models/SubscriptionInfoDTO';
import { ApiUser } from './models/ApiUser';
import { JobRequestUsageDataResponse } from './models/JobRequestUsageDataResponse';

const BASE_URL = 'https://api.klang.io/';

export default class ApiUserService {
  public static getRequestInit() {
    return {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${OpenAPI.TOKEN}`,
      },
    };
  }

  public static usersSubscription(): Promise<SubscriptionResponse> {
    return fetch(`${BASE_URL}users/subscription/`, {
      ...(this.getRequestInit() as any),
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<SubscriptionResponse>;
    });
  }

  public static usersCheckOrCreateUser(): Promise<ApiUser> {
    return fetch(`${BASE_URL}users/check_or_create`, {
      ...(this.getRequestInit() as any),
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<ApiUser>;
    });
  }

  public static usersUsage(days: number): Promise<JobRequestUsageDataResponse> {
    return fetch(`${BASE_URL}users/usage?days=${days}`, {
      ...(this.getRequestInit() as any),
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<JobRequestUsageDataResponse>;
    });
  }
}
