import React from 'react';

import Box from '@mui/material/Box';
import Link, { LinkProps } from '@mui/material/Link';
import { Typography } from '@mui/material';
import RouterLink from './RouterLink';

export type LogoProps = {
  disabledLink?: boolean;
} & LinkProps;

const Logo: React.FC<LogoProps> = ({ disabledLink = false, sx }) => {
  const logo = (
    <Box
      component="img"
      src="/logo/klangio_logo_white.svg"
      sx={{ width: 40, height: 40, cursor: 'pointer' }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link
      component={RouterLink}
      to="/"
      href="/"
      sx={{ display: 'flex', justifyItems: 'center', textDecoration: 'none', ...sx }}
    >
      {logo}
      <Typography color="white" variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Klangio API
      </Typography>
    </Link>
  );
};

export default Logo;
