import React, { Suspense, lazy } from 'react';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ApiIcon from '@mui/icons-material/Api';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import DashboardLayout from './components/Layout';
import { UserProvider } from './context';
import ApiStateProvider from './context/ApiStateProvider';
import ApiUserProvider from './context/ApiUserProvider';

export const Page404 = lazy(() => import('./pages/404'));
export const HomePage = lazy(() => import('./pages/Home'));
export const UserPage = lazy(() => import('./pages/User'));
export const LoginPage = lazy(() => import('./pages/Login'));
export const BillingPage = lazy(() => import('./pages/Billing'));

export const navConfig = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  },
  {
    title: 'Purchases & Billing',
    path: '/billing',
    icon: <PaymentTwoToneIcon />,
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: <AccountCircleTwoToneIcon />,
  },
  {
    title: 'API Documentation',
    path: 'https://api-docs.klang.io/docs/category/getting-started',
    icon: <ArticleTwoToneIcon />,
  },
  {
    title: 'OpenAPI Spec',
    path: 'https://api.klang.io/open_api',
    icon: <ApiIcon />,
  },
];

const baseRouter = createBrowserRouter([
  {
    element: (
      <UserProvider>
        <ApiUserProvider>
          <ApiStateProvider>
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </ApiStateProvider>
        </ApiUserProvider>
      </UserProvider>
    ),
    children: [
      { element: <HomePage />, index: true },
      { element: <UserPage />, path: '/profile' },
    ],
  },
  {
    element: (
      <UserProvider>
        <ApiUserProvider>
          <DashboardLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </ApiUserProvider>
      </UserProvider>
    ),
    children: [{ element: <BillingPage />, path: '/billing' }],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/404',
    element: <Page404 />,
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
]);

export default baseRouter;
