import React, { PropsWithChildren, useEffect } from 'react';
import { UsersService, UserDTO, OpenAPI } from '@klangio/ai2notes-ts-client';
import UserContext, { IUserContextValue } from './UserContext';

export const BASE_URL = `https://ai2notes.klang.io`;
export const DOMAIN_BASE_URL = `${BASE_URL}/api/auth`;

const initOpenApi = () => {
  OpenAPI.WITH_CREDENTIALS = true;
  OpenAPI.BASE = 'https://ai2notes.klang.io';
};

const setOpenApiToken = (sessionToken: string) => {
  OpenAPI.TOKEN = sessionToken;
};

function parseJwt(token: string) {
  const base64Url = token?.split('.')?.[1];
  const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
  if (base64) {
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
  return null;
}

const isJwtTokenStringValid = (token: string) => {
  const jwt = parseJwt(token);
  return jwt && jwt.exp * 1000 > Date.now();
};

const isJwtTokenValid = () => {
  const token = JSON.parse(window.localStorage?.getItem('currentUser') || '')?.token;
  return isJwtTokenStringValid(token);
};

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const USER_KEY = 'currentUser';

  const [user, setUser] = React.useState<UserDTO>();
  const [isLoadingUserCompleted, setIsLoadingUserCompleted] = React.useState<boolean>(false);

  const getUserWithCookie = async () => {
    try {
      const userRequest = await fetch(`${DOMAIN_BASE_URL}/cookie`, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await userRequest?.json();
      if (!data?.token) {
        console.error('No token received from backend.');
        return undefined;
      }
      // get user data
      const response = await fetch(`${BASE_URL}/api/users/me`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${data.token}` },
      });
      const tempUser = await response.text();
      const newUser = JSON.parse(tempUser);
      newUser.token = data.token;
      localStorage.setItem(USER_KEY, JSON.stringify(newUser));
      return newUser;
    } catch (e) {
      console.error(e);
      if ([401, 403].indexOf(e.status) !== -1) {
        if (localStorage.getItem(USER_KEY)) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem(USER_KEY);
        }
      }
      return undefined;
    }
  };

  useEffect(() => {
    initOpenApi();
    if (typeof window !== 'undefined') {
      const getUser = async () => {
        const curUser = window.localStorage?.getItem(USER_KEY);
        if (!curUser) {
          const cookieUser = await getUserWithCookie();
          setUser(cookieUser);
        }
        if (curUser) {
          if (!isJwtTokenValid()) {
            setUser(undefined);
            window.localStorage.removeItem(USER_KEY);
          }
        }
      };

      getUser().finally(() => {
        const currentUser = localStorage.getItem(USER_KEY)
          ? JSON.parse(localStorage.getItem(USER_KEY) || '')
          : undefined;
        setUser(currentUser);
        setOpenApiToken(currentUser?.token);
        setIsLoadingUserCompleted(true);
      });
    }
    return () => {};
  }, []);

  const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem(USER_KEY);
    setUser(undefined);
    return Promise.resolve();
  };

  const refresh = async () => {
    // is user not signed in
    if (!user) return Promise.resolve(undefined);
    return UsersService.usersMeGet()
      .then((res: UserDTO) => {
        localStorage.setItem(USER_KEY, JSON.stringify(res));
        setUser(res);
        return res;
      })
      .catch(() => {
        if (localStorage.getItem(USER_KEY)) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          logout();
        }
      });
  };

  const getNumberOfTickets = () => {
    if (user) {
      let numberOfTickets = 0;
      user.subscriptions?.forEach((subscription) => {
        numberOfTickets += subscription.tickets;
      });
      return numberOfTickets;
    }
    return 0;
  };

  const hasTickets = () => {
    if (user) {
      return user.tickets > 0 || getNumberOfTickets() > 0;
    }
    return false;
  };

  const hasSubscriptionTickets = () => {
    if (user) {
      return getNumberOfTickets() > 0;
    }
    return false;
  };

  const hasSubscription = () => {
    if (user) {
      return (user.subscriptions?.length || -1) > 0;
    }
    return false;
  };

  const handleDeleteUser = async () => {
    try {
      const vals = await UsersService.usersDelete();
      await logout();
      return vals;
    } catch (e) {
      console.log('Logout error');
      console.error(e);
      throw e;
    }
  };

  const value = React.useMemo<IUserContextValue>(
    () => ({
      user,
      hasTicketsForUse: hasTickets(),
      hasSubscriptionTickets: hasSubscriptionTickets(),
      hasSubscription: hasSubscription(),
      logout,
      refresh,
      isLoadingUserCompleted,
      getNumberOfTickets,
      delete: handleDeleteUser,
    }),
    [user, isLoadingUserCompleted]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
