import React from 'react';
import { ApiUser } from '../services/models/ApiUser';

export interface IApiUserContextValue {
  isLoading: boolean;
  userData?: ApiUser;
  refresh: () => void;
}

const ApiUserContext = React.createContext<IApiUserContextValue>(undefined as IApiUserContextValue);

export default ApiUserContext;
