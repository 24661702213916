import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import { useResponsive } from '../hooks/useResponsive';
import { NAV_WIDTH_PX } from './Nav';
import { HEADER_HEIGHT_DESKTOP_PX, HEADER_HEIGHT_MOBILE_PX } from './Header';

const SPACING = 8;

export type MainContentWrapperProps = BoxProps;

const MainContentWrapper: React.FC<MainContentWrapperProps> = ({ children, sx, ...other }) => {
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER_HEIGHT_MOBILE_PX + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER_HEIGHT_DESKTOP_PX + SPACING}px`,
          width: `calc(100% - ${NAV_WIDTH_PX})`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default MainContentWrapper;
