import React, { PropsWithChildren, useState } from 'react';

import Box from '@mui/material/Box';

import Nav from './Nav';
import MainContentWrapper from './MainContentWrapper';
import Header from './Header';

const DashboardLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <MainContentWrapper>{children}</MainContentWrapper>
      </Box>
    </>
  );
};

export default DashboardLayout;
