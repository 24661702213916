import React from 'react';
import { UserDTO } from '@klangio/ai2notes-ts-client';

export interface IUserContextValue {
  user?: UserDTO;
  hasSubscription: boolean;
  hasTicketsForUse: boolean;
  hasSubscriptionTickets: boolean;
  getNumberOfTickets: () => number;
  isLoadingUserCompleted: boolean;
  logout: () => Promise<void>;
  refresh: () => Promise<UserDTO>;
}

export const tryFetchLocalUser = () =>
  (typeof window !== 'undefined'
    ? JSON.parse(window.localStorage.getItem('currentUser') || 'null')
    : null);

export const defaultUserContextValue: IUserContextValue = {
  user: tryFetchLocalUser(),
  hasSubscription: false,
  hasTicketsForUse: false,
  hasSubscriptionTickets: false,
  getNumberOfTickets: () => 0,
  isLoadingUserCompleted: false,
  logout: () => new Promise<void>(() => {}),
  refresh: () => new Promise<UserDTO>(() => {}),
};

const UserContext = React.createContext<IUserContextValue>(defaultUserContextValue);

export default UserContext;
